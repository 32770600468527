<template>
  <el-card :class="{'hot-recommend':true,'header-border-hidden':headerBorder}" v-loading="loading"
           :body-style="{'padding-top':headerBorder?'0px':null,}">
    <template slot="header">
      <slot name="header">
        <div class="clearfix" style="display: flex;">
          <el-image :src="require('@/assets/images/problemList/热门@2x.png')" style="width: 24px; height: 24px"></el-image>
          <span style="color: #00957e">{{ title }}</span>
        </div>
      </slot>
    </template>
    <el-empty image="" :image-size="60" v-if="ListData.length === 0"></el-empty>
    <div v-else :style="{height: (height && !isNaN(height) ? height+'px' : (height || 'auto')),'overflow-y':(height ? 'auto' : 'hidden')}">
      <div class="hot-recommend-item" v-for="(v, i) in ListData" :key="i" @click="handleRoute(v)">
        <el-image :src="require('@/assets/images/problemList/编组7@2x.png')" style="min-width: 24px; height: 24px;max-width: 24px;"></el-image>
        <span class="text" v-html="v.title"></span>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  name: "hotRecommend",
  props:{
    height:{
      type:[String,Number],
      default(){return 'auto'; },
    },
    type:{
      type:String,
      default(){return 'questList';},
      validator(value){
        return ['question','questList','questOther'].includes(value);
      }
    },
    headerBorder: {type:Boolean,default(){return true;}},
    where:Object,
    title: {
      type:String,
      default: () => "热门推荐",
    },
  },
  data(){
    return {
      loading :false,
      ListData:[],
      currentHeight:'auto',
      typeList:{
        question:'/api/v1_0_0.question/recommend',
        questList:'/api/v1_0_0.question_list/hot',
        questOther:'/api/v1_0_0.question_list/hot',
      }
    };
  },
  watch:{
    "$props.height"(){
      this.$nextTick(this.initStyle)
    },
  },
  created() {
    this.$nextTick(this.initStyle)
  },
  mounted() {
    this.getKnowPointTags();
  },
  methods:{
    initStyle(){
      this.currentHeight = (this.height && !isNaN(this.height) ? this.height+'px' : (this.height || 'auto'))
    },
    handleRoute(item){
      if(this.type === 'question'){
        this.$handleRoute({id:item.id},'QuestionInfo');
      }
      if(this.type === 'questList' || this.type === 'questOther'){
        this.$handleRoute({id:item.id},'/problemList/student/problemListDetails');
      }
    },
    getKnowPointTags(){
      this.loading = true;
      this.$http.request({url:this.typeList[this.type],method:'post'}).then(({data})=>{
        this.ListData = [...(data.data || data)]
      }).finally(()=>{
        this.loading = false;
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep.hot-recommend{
  .el-card__header{
    font-weight: 700;
  }
  .hot-recommend-item:not(:first-child){
    margin-top: 10px;
  }
  .hot-recommend-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #363636;
    .text {
      margin-left: 0.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
}

</style>