<template>
  <!-- 分类筛选 -->
  <div class="classify">
    <div class="search" style="margin-bottom: 1rem;">
      <el-row :gutter="4" type="flex" justify="end">
        <el-col :span="checkedOption.isShow ? 21 : 24" class="flex">
         <div style="display:flex;">
           <div class="tag" style="margin-right: 10px;font-weight: 600;font-size: 14px;line-height: 30px;">{{ searchName }}</div>
           <div style="flex: 1;display: inline-flex;">
             <diy-form-item v-bind="keywordOption"
                            @clear="handleEmit('update1' , ...arguments)"
                            @update="handleEmit('update1' , ...arguments)"
                            :style="{width:keywordOptions.width||'400px',fontSize:'12px'}"
                            v-model="form[keywordOption.prop]"
                            :type="'input'"
                            :item-type="0"
                            :fetch-suggestions="querySearch"
             >
               <template slot="append">
                 <el-button icon="el-icon-search" @click="searchClick('button')"></el-button>
               </template>
             </diy-form-item>
             <div style="line-height:32px;margin-left: 10px;cursor: pointer;" @click="searchAllClear({})" v-if="searchOptions.length === 0">清除所有筛选条件</div>
           </div>
         </div>
        </el-col>

        <el-col :span="3" v-show="checkedOption.isShow">
          <!-- <div class="searchChecked">
            <el-checkbox v-model="form[checkedOption.prop]" :true-label="checkedOption.trueLabel" :false-label="checkedOption.falseLabel" :text-color="checkedOption.textColor || '#00957e'">{{checkedOption.label || '搜索内容'}}</el-checkbox>
          </div> -->
        </el-col>
      </el-row>
    </div>
    <!-- 筛选条件 -->
    <div class="typeCourse" v-show="isShowSearch && searchOptions.length > 0" style="display: flex;flex-wrap: wrap;">
      <!-- <div class="empty" style="padding-top: 3px;" @click="searchAllClear({})">清除所有筛选条件</div> -->
      <template  v-for="(item, index) in searchOptions">
        <div class="typeCourse-item" :key="index" v-if="item.options.prop" :style="{width: searchOptions.length>1?'50%':'' }">
          <div class="title">{{ item.options.label }}</div>
          <div style="flex: 1;">
            <diy-form-item :item-type="0" :ref="'search-'+item.options.prop"
                           :type="item.options.type || 'radio'"
                           v-model="form[item.options.prop]"
                           :prop="item.options.prop"
                           v-bind="Object.assign(item.options , {options:{listType:'button'},size:'small'})"
                           :default-value="item.options.defaultValue || null"
                           @update="handleEmit('update' , ...arguments)"
            >
            </diy-form-item>
          </div>
        </div>
      </template>
      <div class="otherOption" style="margin-bottom: 20px;" v-show="isShowSearch && otherOptions.length > 0">
      <div class="title">其他选项</div>
        <div class="otherOption-item" v-for="(item, i) in otherOptions" :key="i">
          <div class="titles">{{ item.options.label }}</div>
            <div>
              <diy-form-item :item-type="0" :ref="'search-'+item.options.prop"
                            :type="item.options.type || 'select'"
                            v-model="form[item.options.prop]"
                            :prop="item.options.prop"
                            v-bind="Object.assign(item.options , {size:'small'})"
                            :default-value="item.options.defaultValue || null"
                            @update="handleEmit('update' , ...arguments)"
              >
              </diy-form-item>
            </div>
      </div>
    </div>
    </div>
    
    <!-- <div class="numCourse" :style="isShowSearch === false ? 'position: relative;' : ''">共{{ total }}条结果</div> -->
    <div class="unfold" @click="isShowSearch = !isShowSearch" v-if="isShowHideBtn">
      <span v-if="isShowSearch"><i class="el-icon-arrow-up"> </i>隐藏高级搜索</span>
      <span v-else><i class="el-icon-arrow-down"> </i>显示高级搜索 </span>
    </div>
  </div>
</template>

<script>
import DiyFormItem from "@/components/com/el/diyFormItem";
export default {
  name: "SearchFilter",
  components: {DiyFormItem},
  model: {
    prop: 'searchForm',
    event: 'changeForm'
  },
  props:{
    searchOptions:{
      type:Array,
      default(){return []; },
    },
    otherOptions:{
      type:Array,
      default(){return []; },
    },
    keywordOptions:{
      type:Object,
      default(){return {prop:'keyword',name:'input'}; },
    },
    total:{
      type:Number,
      default(){return 0; },
    },
    checkedptions:{
      type:Object,
      default(){return {prop:'search_type',label:'搜索内容',trueLabel:1,falseLabel:2,isShow:true}; },
    },
    searchForm:{
      type:Object,
      default(){return {}; },
    },
    isShowHideBtn:{
      type:Boolean,
      default(){return true; },
    },
    searchName:{
      type:String,
      required:true,
    },
  },
  data(){
    return {
      isShowSearch:true,
      form: {},
      confirmForm:{},
      confirmLoading : false,
    };
  },
  computed:{
    keywordOption(){
      let type = ['input','autocomplete'].indexOf(this.keywordOptions.name) > -1 ? this.keywordOptions.name : 'input';
      type = this.keywordOptions.autocomplete === true || this.keywordOptions.autocomplete === 'on' ? 'autocomplete' : 'input';
      return this.$tool.ObjectAssign({clearable:false} , this.keywordOptions.options || {} , {
        title:this.keywordOptions.title || this.searchName  ,
        prop:this.keywordOptions.prop || 'keyword',
        placeholder:this.keywordOptions.placeholder || this.searchName,
        clearable:this.keywordOptions.clearable !== false,
        autocomplete:type === 'autocomplete',
        autoName:this.keywordOptions.autoName || null,
      })
    },
    checkedOption(){
      return this.$tool.ObjectAssign({trueLabel:1,falseLabel: 2} , this.checkedptions.options || {} , {
        prop:this.checkedptions.prop || 'search_type',
        isShow:this.checkedptions.isShow !== false,
        label:this.checkedptions.label || '搜索内容',
      })
    }
  },
  watch:{
    confirmForm(){
      this.handleEmitSearch();
    },
    searchForm(v){
      if(this.$tool.obj_equal(this.confirmForm , v) === false) {
        var form = this.searchAllDefault(v);
        if (this.$tool.obj_equal(this.confirmForm, form) === false) {
          this.confirmForm = form;
          this.form = this.searchAllDefault(v , true);
        }
      }
    },
    isShowSearch(v){
      this.$nextTick(()=>{
        this.$emit('eleChange',this.isShowSearch);
      });
    },
  },
  mounted() {
    let form =  this.searchAllDefault(this.searchForm);
    this.confirmForm = form;
    this.form = Object.assign({} , form);
    // 在 mounted 钩子中添加键盘事件监听
    this.$nextTick(() => {
      window.addEventListener('keyup', (e) => {
        if (e.key === 'Enter') {
          // 调用你的搜索方法
          this.searchClick('button')
        }
      });
    });
  },
  methods:{
    handleEmitSearch(){
      this.$emit('changeForm', this.confirmForm);
      this.$emit("search", this.confirmForm)
      // console.log(this.confirmForm,'confirmFormconfirmForm.');
    },
    handleEmit(type , ...vars){
      if(type === 'update'){
        let form =  this.searchAllDefault(this.form);
        if (this.$tool.obj_equal(this.confirmForm, form) === false) {
          this.confirmForm = form;
        }
      }else if(type === 'update1'){
        if(!this.form[this.keywordOption.prop]){
          let confirmForm = {...this.confirmForm}
          delete confirmForm[this.keywordOption.prop];
          delete confirmForm[this.checkedOption.prop];
          this.confirmForm = confirmForm;
        }
      }else{
        this.$emit(type, ...vars);
      }
    },
    searchAllClear(){
      this.confirmForm = this.searchAllDefault();
      this.form = this.searchAllDefault({} , true);
    },
    searchAllDefault(_form , defaltSet = false){
      _form = _form || {};
      let keyword = this.keywordOption.prop
      let checked = this.checkedOption.prop
      var props = [keyword , checked];
      var defaultForm = {};
      if(_form[keyword] !== undefined && _form[keyword] !== null && _form[keyword].trim() !== ''){
        defaultForm[keyword] = _form[keyword]
        if(_form[checked] !== undefined)defaultForm[checked] = _form[checked]
      }
      var getDefault = (optionArray)=>{
        // console.log(optionArray,"optionArray");
        for (let i = 0; i < optionArray.length; i++) {
          let options = optionArray[i].props || optionArray[i].options || {};
          let prop = options.prop;
          if(prop){
            props.push(prop);
            let defaultValue = options.defaultValue;
            let value;
            if(_form[prop] !== undefined && _form[prop] !== null && _form[prop] !== '' && !(defaultValue === '全部' && defaultValue === _form[prop])){
              value = _form[prop];
            }
            if(value === undefined && (defaltSet || (!defaltSet && defaultValue !== '全部')) && defaultValue !== undefined){
              value = defaultValue;
            }
            if(value !== undefined) defaultForm[prop] = value
          }
        }
      }
      getDefault(this.otherOptions);
      getDefault(this.searchOptions);
      // console.log(this.searchOptions,"this.searchOptions");
      for (const formKey in _form) {
        if(props.indexOf(formKey) === -1){
          defaultForm[formKey] = _form[formKey];
        }
      }
      return defaultForm;
    },
    searchClick(e){
      let keyword = this.keywordOption.prop
      let checked = this.checkedOption.prop
      if (e === 'button' && (this.form[keyword] === undefined || this.form[keyword] === null || (this.form[keyword]).trim() === '')) {
        // this.$message.warning( '请输入关键词')
      }else{
        let confirmForm = {...this.confirmForm}
        confirmForm[keyword] = this.form[keyword]
        if(this.form[checked] !== undefined)confirmForm[checked] = this.form[checked]

        this.recordName(this.form[keyword]);

        this.confirmForm = confirmForm
      }
    },
    recordName(record){
      if(!this.keywordOption.autoName) return [];
      let recordName = 'searchRecord:'+this.keywordOption.autoName
      let records = sessionStorage.getItem(recordName)
      records = records ? JSON.parse(records) : [];
      if(record !== undefined && record !== null && record.trim() !== ''){
        let index = records.indexOf(record);
        if(index > -1){
          records.splice(index , 1);
        }
        records.unshift(record)
        sessionStorage.setItem(recordName,JSON.stringify(records))
      }
      return records;
    },
    querySearch(queryString, cb) {
      let records = this.recordName()
      records? cb(records.map(v=>{
        return {
          value:v
        }
      })): cb([])
    },
  }
}
</script>

<style lang="less" scoped>
.classify {
  .typeCourse {
    //line-height: 2.5rem;
    font-size: 14px;
  }
  position: relative;
  /deep/ .search {
    width: 100%;

    // .tag {
      // font-size: 18px;
      // line-height: 30px;
      // color: #3a3a3a;
    // }
    .el-input--medium{
      font-size: 13px !important;
    }
    .el-input__inner {
      height: 32px;
      line-height: 32px;
      border-radius: 40px 0 0 40px;
      border: 1px solid #d4d4d4;
      border-right: none;
    }
    .el-input__suffix,.el-input__suffix [class*=" el-icon-"], [class^=el-icon-]{
      height: 32px;
      line-height: 32px;
    }
    .el-input .el-input-group__append, .el-input .el-input-group__prepend{
      border:none;
      top: -0.5px;
    }
    .el-button {
      height: 32px !important;
      width: 53px;
      padding: 0;
      text-align: center;
      box-sizing: border-box;
      border-radius: 0 40px 40px 0;
      border: 1px solid #00957e;
      border-left: none;
      background: #00957e;
      .el-icon-search {
        color: #fff;
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #00957e;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #00957e;
      border-color: #00957e;
    }
  }
  // height: 237px;
  box-sizing: border-box;
  .otherOption {
    display: flex;
    align-items: center;
    position: relative;
    .otherOption-item {
      margin-right: 1.5rem;
      display: flex;
      align-items: center;
      .titles {
        font-size: 14px;
        margin-right: 0.5rem;
      }
    }
  }
  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    margin-right: 1.8rem;
    padding: 7px 0;
  }
  .empty {
    float: right;
    cursor: pointer;
  }
  .numCourse {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 14px;
  }
  .typeCourse-item {
    display: flex;
    //align-items: center;
    position: relative;
    margin-bottom: 10px;

    > .title{
      margin-bottom: 10px;
    }
    .el-radio__input,.el-checkbox__input{
      display: none;
    }
    .el-radio.is-checked,.el-checkbox.is-checked{
      color: #ffffff;
      background-color: #00957e;
    }
    .el-radio__input.is-checked+.el-radio__label,.el-checkbox__input.is-checked+.el-checkbox__label{
      color: inherit !important;
    }
  }
  .unfold {
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3a3a3a;
    i {
      margin-right: 8px;
    }
  }
  .searchChecked {
    margin-left: 16px;
    line-height: 30px;
  }
}
</style>