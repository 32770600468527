<template>
  <div>
    <Breadcrumb></Breadcrumb>

    <el-row style="margin: 0 0 10px">
      <el-col :span="24">
        <el-card>
          <div style="display: flex; justify-content: space-between">
            <search-filter
            style="flex: 1;"
              :searchName="'教案名称'"
              :keywordOptions="{ placeholder: '请输入教案名称' }"
              :searchOptions="
                whereOptions.filter(
                  (d) => ['tid', 'cid'].indexOf(d.options.prop) > -1
                )
              "
              :otherOptions="
                whereOptions.filter(
                  (d) => ['tid', 'cid'].indexOf(d.options.prop) === -1
                )
              "
              v-model="where"
              :total="total"
              @search="$refs.table.reset(...arguments)"
            ></search-filter>
            <div class="operate">
              <el-button
                size="small"
                type="primary"
                style="height: 30px"
                v-if="userInfo.type == 2"
                @click="$handleRoute('teachPlanMy')"
                >我的教案</el-button
              >
              <el-button
                :disabled="!$isPowers('teachPlanCourseCreate')"
                size="small"
                type="primary"
                style="height: 30px"
                @click="$handleRoute('teachPlanCourseCreate')"
                >创建教案</el-button
              >
            </div>
          </div>
        </el-card>
        <!-- <div class="operate" style="margin-top: 10px">
          <el-button-group>
            <el-button
              size="small"
              type="primary"
              v-if="userInfo.type == 2"
              @click="$handleRoute('teachPlanMy')"
              >我的教案</el-button
            >
          </el-button-group>
          <el-button-group style="margin-left: 20px">
            <el-button
              :disabled="!$isPowers('teachPlanCourseCreate')"
              size="small"
              type="primary"
              @click="$handleRoute('teachPlanCourseCreate')"
              >创建教案</el-button
            >
          </el-button-group>
        </div> -->
      </el-col>
    </el-row>
    <el-card class="table-box" :body-style="{ padding: '20px 10px' }">
      <com-table
        ref="table"
        :data="tableData"
        :columns="[]"
        :page-size="12"
        :total.sync="total"
        :auto-load="false"
        list-type="table-list"
        :row-page="6"
      >
        <div style="padding: 0 10px; margin-bottom: 15px" slot-scope="{ row }">
          <el-card
            :body-style="{ padding: 0 }"
            :style="{ borderRadius: '15px 15px 0 0' }"
          >
            <div style="width: 100%; height: 9rem">
              <el-image
                :src="row.cover"
                style="width: 100%; height: 100%"
                v-href="{
                  name: 'teachPlanCourseDetail',
                  query: { course_id: row.id },
                }"
              >
              </el-image>
            </div>
            <div class="">
              <div
                class="title"
                v-href="{
                  name: 'teachPlanCourseDetail',
                  query: { course_id: row.id },
                }"
              >
                {{ row.course_name }}
              </div>
            </div>
            <div class="time">创建时间：{{ row.create_time }}</div>
            <div
              :style="{
                padding: '8px 14px',
              }"
            >
              <!-- <div style="
                  font-size: 90%;
                  line-height: 1.5;
                  color: rgba(254,81,52,1);
                  display: flex;
                  justify-content: space-between;
                ">
               <div>
                {{ row.price > 0 ? "普通¥" + row.price : "免费" }}
              </div>
               <div v-if="row.price2>0">
                高级¥{{ row.price2 }}
              </div>
            </div> -->

              <div
                :style="{
                  fontSize: '90%',
                  color: 'rgb(136, 136, 136)',
                }"
              >
                <span> {{ row.cid_name }} · {{ row.buy_num || 0 }}人购买 </span>
                <!-- <span>{{ item.collect_num }}人收藏</span> -->
              </div>
            </div>
            <div :style="{ padding: '8px 14px' }">
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div
                  style="
                    display: flex;
                    line-height: 40px;
                    justify-content: space-between;
                  "
                >
                  <el-avatar :src="row.teach_headimg"></el-avatar>
                  <div
                    style="margin-left: 5px; font-weight: 700; font-size: 14px"
                    class="shenluehao"
                  >
                    {{ row.teach_name }}
                  </div>
                </div>
                <div
                  style="
                    box-sizing: border-box;
                    font-size: 90%;
                    line-height: 1.5;
                    color: rgba(254, 81, 52, 1);
                  "
                >
                  <div>
                    {{ row.price > 0 ? "普通¥" + row.price : "免费" }}
                  </div>
                  <div v-if="row.price2 > 0">高级¥{{ row.price2 }}</div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </com-table>
    </el-card>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  components: { HotRecommend, SearchFilter },
  data() {
    return {
      where: {},
      whereOptions: [
        {
          name: "el-form-item",
          options: {
            label: "类型",
            prop: "tid",
            type: "radio-group",
            data: { name: "course", keys: "type" },
            defaultValue: "全部",
          },
        },
        {
          name: "el-form-item",
          options: {
            label: "类别",
            prop: "cid",
            type: "checkbox",
            data: { name: "course", keys: "cate" },
            defaultValue: "全部",
          },
          // options:{label:'类别' , prop:'cid', type:'radio-group'  , data:{ name:'course', keys:'cate' } , defaultValue :'全部'}
        },
        // { name:'el-form-item' ,
        //   options:{label:'创建人' , prop:'user_id', type:'select' , data:{ url:'/xapi/course.course/teacherList', keys:"user_id" }}
        // },
        // {name:'form-item',props:{prop:'user_id',label:'教案' , type:'select',data:{ url:'/xapi/course.course/myPlanList', labelOptions:{value:'id',label:'course_name'} }},callback(c){c.props.disabled = _this.course_id >0 && _this.courseInfo.pid > 0;}

        // },
        // { name:'el-form-item' ,
        //   options:{label:'时间' , prop:'date', type:'year' , placeholder:'请选择时间'}
        // },
      ],
      total: 0,
      tableData: {
        url: "/xapi/course.course/planList",
        params: { ...this.$route.query },
      },
    };
  },
  created() {
    localStorage.removeItem("pageSize");
  },
  methods: {
    routeUpdate() {
      console.log(this.tableData.params, "this.$route.query");
      if (
        !this.$tool.obj_equal({ ...this.$route.query }, this.tableData.params)
      ) {
        this.tableData.params = { ...this.$route.query };
        this.$refs.table.reset();
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .table-box {
  .el-table .el-table__cell.table-cell-image,
  .el-table .el-table__cell.table-cell-image,
  .el-table .el-table__cell.table-cell-image.is-center {
    padding: 0 !important;
  }
}
// ::v-deep {
//   .el-card__body {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     .classify {
//       flex: 1;
//     }
//   }
// }
.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 14px;
  font-size: 1rem;
  font-weight: bold;
  color: #050505;
  line-height: 1.56rem;
}
.time {
  font-size: 0.8rem;
  box-sizing: border-box;
  padding: 0 14px;
  font-weight: 400;
  color: #646464;
  line-height: 1.5rem;
}
// .operate {
//   display: flex;
//   justify-content: flex-end;
// }
</style>